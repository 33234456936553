import { render, staticRenderFns } from "./VariantList.vue?vue&type=template&id=284f0f46&scoped=true"
import script from "./VariantList.vue?vue&type=script&lang=js"
export * from "./VariantList.vue?vue&type=script&lang=js"
import style0 from "./VariantList.vue?vue&type=style&index=0&id=284f0f46&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "284f0f46",
  null
  
)

export default component.exports